import * as React from 'react';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import EmbedYouTube from '../../../components/embed-youtube';

import PartnersPage from '../../../components/partners/partners';
import { ReactComponent as MondayLogoSvg } from './monday-logo.svg';

const pageTitle = 'Partnership with monday.com';

const PartnersMonday = () => (
  <>
    <GatsbySeo
      language="en"
      title={pageTitle}
      _description=""
      openGraph={{
        url: 'https://www.dsapps.dev/partners/monday/',
      }}
    />

    <PartnersPage pageTitle={pageTitle}>
      <div className="container flex flex-col md:flex-row">
        <div className="flex flex-col mb-16 md:w-2/3 lg:pr-24 md:pr-16 md:items-start md:text-left md:mb-0">
          <div className="text-lg md:text-xl  lg:text-2xl leading-8">
            <p>
              We've teamed up with monday.com to enhance their workOS by
              introducing some amazing apps that make collaboration,
              brainstorming & project planning even better.
            </p>
          </div>

          <div>
            <Link className="button-primary" to="/products/#/monday.com">
              View our monday.com apps
            </Link>
          </div>

          {/* <Rule /> */}

          <p className="mt-12">
            The monday.com Work OS is a versatile platform designed to
            streamline and improve teamwork and project management within
            organisations. It serves as a centralized hub where teams can
            collaborate, plan, and execute their work efficiently.
          </p>

          <div>
            <Link
              className=" button-secondary"
              target="_blank"
              href="https://try.monday.com/lygb10kf0fvj"
            >
              Try monday.com for free
            </Link>
          </div>
        </div>
        <div className="items-center __md:mt-10 md:w-2/5">
          <EmbedYouTube
            className="mb-14 mx-auto"
            id="WdPsUyNS3-g"
            autoplay="0"
          />

          <Link target="_blank" href="https://try.monday.com/lygb10kf0fvj">
            <MondayLogoSvg />
          </Link>

          <div className="text-center my-12">
            <OutboundLink
              href="https://try.monday.com/lygb10kf0fvj"
              target="_blank"
              className="inline-block"
            >
              <StaticImage
                style={{ maxWidth: '150px' }}
                alt="monday.com • certified partner"
                title="monday.com • certified partner"
                src="monday-certified-partner.png"
              />
            </OutboundLink>
          </div>
        </div>
      </div>
    </PartnersPage>
  </>
);

export default PartnersMonday;
